import { all, call, fork, put } from '@redux-saga/core/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { take } from 'redux-saga/effects'
import attenduApi from '../../http/attenduApi'
import {
  Attendu,
  AttenduArrayInterface,
  AttenduInterface,
  TotalAccessoriesApi,
} from '../../models'
import {
  clearList,
  clearTotalNumber,
  createAttendu,
  createAttenduFailed,
  createAttenduSuccess,
  getAllAttendu,
  getAllAttenduFailed,
  getAllAttenduSuccess,
  GetAllPayload,
  getTotalAttendu,
  getTotalAttenduFailed,
  getTotalAttenduSuccess,
  setAttenduListRealtime,
  updateAttenduList,
} from '../reducers/attenduSlice'
import { ActionRealtimeType } from '../../proto/type'
import Define from '../../constants/define'

function* fetchCreateAttendu(payload: Attendu) {
  try {
    const item: AttenduInterface = yield call(attenduApi.createAttendu, payload)
    yield put(createAttenduSuccess(item.data.entry))
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(createAttenduFailed(error.response?.data.error))
    } else {
      yield put(createAttenduFailed('Something went wrong'))
    }
  }
}

function* fetchAllAttendus(payload: GetAllPayload) {
  try {
    if (localStorage.getItem(Define.CHOOSING_CLIENT)) {
      const item: AttenduArrayInterface = yield call(
        attenduApi.getAllAttendu,
        {}
      )
      yield put(getAllAttenduSuccess(item))
    } else {
      yield put(clearList())
    }
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(getAllAttenduFailed(error.response?.data.error))
    } else {
      yield put(getAllAttenduFailed('Something went wrong'))
    }
  }
}

function* fetchTotalAttendus(payload: GetAllPayload) {
  try {
    if (localStorage.getItem(Define.CHOOSING_CLIENT)) {
      const item: TotalAccessoriesApi = yield call(
        attenduApi.getTotalAttendu,
        payload
      )
      yield put(getTotalAttenduSuccess(item))
    } else {
      yield put(clearTotalNumber())
    }
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(getTotalAttenduFailed(error.response?.data.error))
    } else {
      yield put(getTotalAttenduFailed('Something went wrong'))
    }
  }
}

function* updateAttenduInList(payload: {
  attenduChange: Attendu
  actionType: ActionRealtimeType
}) {
  try {
    if (
      [ActionRealtimeType.CREATE, ActionRealtimeType.DELETE].includes(
        payload.actionType
      )
    ) {
      const res: AttenduArrayInterface = yield call(attenduApi.getAllAttendu, {
        offset: 1,
        limit: 1,
      })
      yield put(
        updateAttenduList({ ...payload, totalRecord: res.data.metadata.total })
      )
    } else {
      yield put(updateAttenduList(payload))
    }
  } catch (error) {}
}

function* watchAttenduFlow() {
  while (true) {
    const type: PayloadAction<GetAllPayload> = yield take(getAllAttendu.type)
    if (type) {
      yield fork(fetchAllAttendus, type.payload)
    }
  }
}

function* watchTotalAttenduFlow() {
  while (true) {
    const type: PayloadAction<GetAllPayload> = yield take(getTotalAttendu.type)
    if (type) {
      yield fork(fetchTotalAttendus, type.payload)
    }
  }
}

function* watchCreateAttenduFlow() {
  while (true) {
    const attendu: PayloadAction<Attendu> = yield take(createAttendu.type)
    if (attendu) {
      yield fork(fetchCreateAttendu, attendu.payload)
    }
  }
}

function* watchSetAttenduFlow() {
  while (true) {
    const attendu: PayloadAction<any> = yield take(setAttenduListRealtime.type)
    if (attendu) {
      yield fork(updateAttenduInList, attendu.payload)
    }
  }
}

export default function* attenduSaga() {
  yield all([
    fork(watchAttenduFlow),
    fork(watchCreateAttenduFlow),
    fork(watchTotalAttenduFlow),
    fork(watchSetAttenduFlow),
  ])
}
