import { all, call, fork, put } from '@redux-saga/core/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { take } from 'redux-saga/effects'
import commandeApi from '../../http/commandeApi'
import {
  Commande,
  CommandeArrayInterface,
  CommandeInterface,
  TotalAccessoriesApi,
} from '../../models'
import {
  clearList,
  clearTotalNumber,
  createCommande,
  createCommandeFailed,
  createCommandeSuccess,
  getAllCommande,
  getAllCommandeFailed,
  getAllCommandeSuccess,
  GetAllPayload,
  getTotalCommande,
  getTotalCommandeFailed,
  getTotalCommandeSuccess,
} from '../reducers/commandeSlice'
import Define from '../../constants/define'

function* createNewCommande(payload: Commande) {
  try {
    const item: CommandeInterface = yield call(
      commandeApi.createCommande,
      payload
    )
    // @ts-ignore
    yield put(createCommandeSuccess(item.data.entry))
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(createCommandeFailed(error.response?.data.error))
    } else {
      yield put(createCommandeFailed('Something went wrong'))
    }
  }
}

function* fetchAllCommandes(payload: GetAllPayload) {
  try {
    if (localStorage.getItem(Define.CHOOSING_CLIENT)) {
      const item: CommandeArrayInterface = yield call(
        commandeApi.getAllCommande,
        payload
      )
      yield put(getAllCommandeSuccess(item))
    } else {
      yield put(clearList())
    }
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(getAllCommandeFailed(error.response?.data.error))
    } else {
      yield put(getAllCommandeFailed('Something went wrong'))
    }
  }
}

function* fetchTotalCommandes(payload: GetAllPayload) {
  try {
    if (localStorage.getItem(Define.CHOOSING_CLIENT)) {
      const item: TotalAccessoriesApi = yield call(
        commandeApi.getTotalCommande,
        payload
      )
      yield put(getTotalCommandeSuccess(item))
    } else {
      yield put(clearTotalNumber())
    }
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(getTotalCommandeFailed(error.response?.data.error))
    } else {
      yield put(getTotalCommandeFailed('Something went wrong'))
    }
  }
}

function* watchCommandeFlow() {
  while (true) {
    const type: PayloadAction<GetAllPayload> = yield take(getAllCommande.type)
    if (type) {
      yield fork(fetchAllCommandes, type.payload)
    }
  }
}

function* watchTotalCommandeFlow() {
  while (true) {
    const type: PayloadAction<GetAllPayload> = yield take(getTotalCommande.type)
    if (type) {
      yield fork(fetchTotalCommandes, type.payload)
    }
  }
}

function* watchCreateCommandeFlow() {
  while (true) {
    const commande: PayloadAction<Commande> = yield take(createCommande.type)
    if (commande) {
      yield fork(createNewCommande, commande.payload)
    }
  }
}

export default function* commandeSaga() {
  yield all([
    fork(watchCreateCommandeFlow),
    fork(watchCommandeFlow),
    fork(watchTotalCommandeFlow),
  ])
}
