import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StatusAPI } from '../../enum'
import { MissionData, MissionArrayInterface } from '../../models'
import { Metadata } from '../../models/common/common'

export interface GetAllPayload {
  limit: Number
  offset: Number
}

interface missionList {
  status: StatusAPI
  list: MissionData[]
  loading: boolean
  metadata: Metadata
}

export interface missionState {
  missionList: missionList
  error: string
}

const initialState: missionState = {
  missionList: {
    status: StatusAPI.initial,
    list: [],
    loading: false,
    metadata: {
      count: 0,
      limit: 0,
      total: 0,
    },
  },
  error: '',
}

const missionSlice = createSlice({
  name: 'Mission',
  initialState,
  reducers: {
    clearList(state) {
      state.missionList = initialState.missionList
    },
    getMissionsRequest(state) {
      state.missionList.status = StatusAPI.calling
      state.missionList.loading = true
    },
    getMissionsSuccess(state, action: PayloadAction<MissionArrayInterface>) {
      state.missionList.status = StatusAPI.success
      state.missionList.list = action.payload.data.entry
      state.missionList.loading = false
      state.missionList.metadata = action.payload.data.metadata
    },
    getMissionsFailed(state, action: PayloadAction<any>) {
      state.missionList.status = StatusAPI.failure
      state.error = action.payload
      state.missionList.loading = false
    },
  },
})

export const {
  clearList,
  getMissionsRequest,
  getMissionsSuccess,
  getMissionsFailed,
} = missionSlice.actions
export default missionSlice.reducer