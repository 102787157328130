import {
  clearList,
  getMissionsRequest,
  getMissionsSuccess,
  getMissionsFailed,
  GetAllPayload
} from '../reducers/missionSlice'
import { all, call, fork, put } from '@redux-saga/core/effects'
import { AxiosError } from 'axios'
import { PayloadAction } from '@reduxjs/toolkit'
import { take } from 'redux-saga/effects'
import missionApi from '../../http/missionApi'
import { MissionArrayInterface } from '../../models'
import Define from '../../constants/define'

function* fetchMissions(payload: GetAllPayload) {
  try {
    if (localStorage.getItem(Define.CHOOSING_CLIENT)) {
      const item: MissionArrayInterface = yield call( missionApi.getAllMission, {} )
      yield put(getMissionsSuccess(item))
    } else {
      yield put(clearList())
    }
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(getMissionsFailed(error.response?.data.error))
    } else {
      yield put(getMissionsFailed('Something went wrong'))
    }
  }
}

function* watchMissionFlow() {
  while (true) {
    const type: PayloadAction<GetAllPayload> = yield take(getMissionsRequest.type)
    if (type) {
      yield fork(fetchMissions, type.payload)
    }
  }
}

export default function* missionSaga() {
  yield all([fork(watchMissionFlow)])
}
