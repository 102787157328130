import Define from '../constants/define'
import { getUrlFilterParams } from '../utils'
import {
    MissionInterface,
    Mission,
    ResponseGenerator,
    MissionUtilisateurArrayInterface,
    MissionTypeArrayInterface,
    MissionEtatArrayInterface,
    MissionDeleteInterface
} from '../models'
import baseAxios from "./clientAPI";




interface MissionResponse extends ResponseGenerator {
    data: {
        entry: Mission,
    }
}


const missionApi = {
    getMissionDetail(id: string): Promise<MissionResponse> {
        const params = {id};
        const url = `/warehouse/mission`;
        return baseAxios.get(url, {params});
    },

    getMissionType(): Promise<MissionTypeArrayInterface> {
        const url = '/warehouse/mission/list-type';
        return baseAxios.get(url);
    },

    getMissionEtat(): Promise<MissionEtatArrayInterface> {
        const url = '/warehouse/mission/list-progress';
        return baseAxios.get(url);
    },

    getUtilisateur(payload: {
        client_id: string
        warehouse_id: string
        company_id: string
    }): Promise<MissionUtilisateurArrayInterface> {
        const url = '/warehouse/mission/list-ultilsateur';
        const params = {
            client_id: payload.client_id,
            company_id: payload.warehouse_id,
            warehouse_id: payload.company_id,
        };
        return baseAxios.get(url, { params });
    },

    getAllMission(payload?: {
        limit?: number
        offset?: number
    }): Promise<MissionInterface> {
        const url = '/warehouse/mission/all';
        const { condition, pageSize, pageIndex } = getUrlFilterParams();
        const params = {
            limit: pageSize,
            offset: (pageIndex - 1) * pageSize || 0,
            client_id: localStorage.getItem(Define.CHOOSING_CLIENT),
            company_id: localStorage.getItem(Define.CHOOSING_COMPANY),
            warehouse_id: localStorage.getItem(Define.CHOOSING_WAREHOUSE),
            ...condition,
        };
        return baseAxios.get(url, { params });
    },

    deleteMission(missionId: string): Promise<MissionDeleteInterface> {
        const url = '/warehouse/mission/delete';
        const params = { mission_id: missionId }
        return baseAxios.delete(url, { params });
    },
}

export default missionApi
